/* Ogólne style dla komponentu App */
.App {
  font-family: sans-serif;
  margin: 0;
  height: 100%;
}

/* Style nagłówka */
header {
  background-color: #f0f0f0;
  text-align: center;
  height: 4vh;
  font-size:larger;
}


/* Style linków w nav */
.link {
  color: #000;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border-radius: 4px;
  display: inline-block;
  text-align: center;
}

.link:hover {
  background-color: #ddd;
}

/* Style aktywnych linków (opcjonalnie) */
.link.active {
  font-weight: bold;
}

/* Style komponentu ContactComponent */


.data{
  width: 30vw;
  height: 30vh;
  margin: 0 auto;
  text-align: center;
  padding-top: 10vh;
  background-color: rgba(240, 240, 240, 0.85);
  border-radius: 10px;
  vertical-align: middle;
}

.data > button{
  text-decoration: none;
  color: #000;
  border: 2px solid #f0f0f0;
  background-color: #f0f0f0;
  width: 100px;
  height: 5vh;
  border-radius: 4px;
}

.data>button:hover {
  background-color: #ddd;
  border-color: #ddd;
}

.data>button.active {
  font-weight: bold;
}

/* Centrowanie zawartości nav */
nav {
  text-align: center;
  background-color: #d6d6d6;
  height: 4vh;
}

/* Centrowanie tekstu w linkach */
.link {
  display: inline-block;
  text-align: center;
}

.bg{
    display: flex;
    height: 92vh;
    width: 100vw;
    background-image: url(../S.webp);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    vertical-align: middle;
    align-items: center;
}


@media (max-width: 768px){
  .App {
    font-family: sans-serif;
    margin: 0;
    height: 100%;
  }
  
  /* Style nagłówka */
  header {
    background-color: #f0f0f0;
    text-align: center;
    height: 4vh;
    font-size:larger;
  }
  
  
  /* Style linków w nav */
  .link {
    color: #000;
    text-decoration: none;
    padding: 0.5rem 1rem;
    margin: 0 0.5rem;
    border-radius: 4px;
    display: inline-block;
    text-align: center;
  }
  
  .link:hover {
    background-color: #ddd;
  }
  
  /* Style aktywnych linków (opcjonalnie) */
  .link.active {
    font-weight: bold;
  }
  
  /* Style komponentu ContactComponent */
  
  
  .data{
    width: 60vw;
    height: 30vh;
    margin: 0 auto;
    text-align: center;
    padding-top: 10vh;
    background-color: rgba(240, 240, 240, 0.85);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
  
  .data > button{
    text-decoration: none;
    color: #000;
    border: 2px solid #f0f0f0;
    background-color: #f0f0f0;
    width: 100px;
    height: 5vh;
    border-radius: 4px;
  }
  
  .data>button:hover {
    background-color: #ddd;
    border-color: #ddd;
  }
  
  .data>button.active {
    font-weight: bold;
  }
  
  /* Centrowanie zawartości nav */
  nav {
    text-align: center;
    background-color: #d6d6d6;
    height: 4vh;
    width: 100vw;
  }
  
  /* Centrowanie tekstu w linkach */
  .link {
    display: inline-block;
    text-align: center;
  }
  
  .bg{
      display: flex;
      height: 92vh;
      width: 100vw;
      background-image: url(../S.webp);
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      vertical-align: middle;
      align-items: center;
  }
}